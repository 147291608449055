<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">精品课程</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="课程分类" class="searchboxItem ci-full">
              <span class="itemLabel">课程分类:</span>
              <el-select v-model="courseType" placeholder="请选择课程分类" size="small" clearable>
                <el-option
                  v-for="item in courseTypeList"
                  :key="item.courseTypeId"
                  :label="item.name"
                  :value="item.courseTypeId"
                ></el-option>
              </el-select>
            </div>
            <div title="是否上架" class="searchboxItem ci-full">
              <span class="itemLabel">是否上架:</span>
              <el-select
                v-model="isUsable"
                placeholder="请选择是否上架"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in isUsableList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="courseAdd()">新增</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed width="100"/>
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                show-overflow-tooltip
                fixed
              />
              <el-table-column
                label="课程分类"
                align="left"
                show-overflow-tooltip
                width="120"
                prop="courseTypeName"
              />
              <el-table-column
                label="苹果单价(元)"
                align="right"
                prop="iosPrice"
                width="120"
                show-overflow-tooltip
              />
              <el-table-column
                label="安卓单价(元)"
                align="right"
                prop="androidPrice"
                 width="120"
                show-overflow-tooltip
              />
              <el-table-column
                label="订单量(个)"
                align="right"
                prop="orderNum"
                 width="120"
                show-overflow-tooltip
              />
              <el-table-column
                label="是否上架"
                align="center"
                show-overflow-tooltip
                prop="isUsable"
                width="120"
                fixed="right"
              >
                <template slot-scope="scope">{{scope.row.isUsable == true ? '是':'否'}}</template>
              </el-table-column>
              <el-table-column
                label="添加时间"
                align="center"
                show-overflow-tooltip
                prop="createTime"
                width="160"
                fixed="right"
              >
              <template slot-scope="scope">
                  {{scope.row.createTime | moment}}
              </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" align="center" width="80px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="EditDetail(scope.row.courseId)"
                  >编辑</el-button>
                  <!-- <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="EditDelete(scope.row.courseId)"
                  >删除</el-button> -->
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "koniptList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      courseName: "",
      courseType: "",
      courseTypeList: [],
      isUsable:'',
    isUsableList:[
          {
              value:'',
              label:'全部'
          },
          {
              value:true,
              label:'是'
          },
          {
              value:false,
              label:'否'
          }
      ],
    };
  },
  created() {
      this.getcourseTypeList()
  },
  computed: {},
  methods: {
      //获取分类
    getcourseTypeList() {
        this.$post('/biz/ec/course/type/list',{}, 3000, true, 2).then(ret => {
            this.courseTypeList = ret.data || []
        }).catch(err => {
            return;
        })
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        isUsable:this.isUsable
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.courseType) {
        params.courseTypeId = this.courseType;
      }
      this.doFetch({
        url: "/biz/ec/course/coursePageList",
        params,
        pageNum
      },true,2);
    },
    //新增
    courseAdd() {
        this.$router.push({
        path: "/web/CourseManagement/CourseListDetails",
      });
    },
    //编辑
    EditDetail(courseId) {
      this.$router.push({
        path: "/web/CourseManagement/CourseListDetails",
        query: {
          courseId,
          stu:'edit'
        }
      });
    },
    //删除
    EditDelete(courseId) {
       this.doDel({
        url: "/biz/ec/course/deleteCourse",
        msg: "是否确认删除？",
        ps: {
          type: "post",
          data: { courseId }
        }
      },true,2);
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {

          console.log(val.query.refresh);
        if (this.$route.query.refresh == true) {
            console.log(123123123);
          this.getData();
        }
      },
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style lang="less">
</style>
